body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#section_1 {
  position: relative;
  background-image: url('../public/festava-live/images/accueil/accueil-bg.jpg');
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}

.containerCircle {
  position: absolute;
  top: 2%;
  right: 2%;
  display: inline-flex;
  justify-content: center; /*space-around;*/
  /*align-items: center;*/
  /*margin-top: -90px;*/
  z-index: 3;
}

.circleContent {
  text-align: center;
  width: 120px; /* Taille du cercle */
}

.imageCircle {
  width: 110px; /* Taille de l'image à l'intérieur du cercle */
  height: 110px;
  border-radius: 50%;
  border: 2.3px solid #ccc;
  outline-offset: -4px;
  background-size: cover;
  background-position: center;
  margin: 0 auto; /* Centrer l'image */
}

.imageCircle1 {
  background-image: url('../public/festava-live/images/accueil/accueil-trot-attele.jpg');
}
.imageCircle2 {
  background-image: url('../public/festava-live/images/accueil/accueil-trot-monte.jpg');
}
.imageCircle3 {
  background-image: url('../public/festava-live/images/accueil/accueil-galop.jpg');
}

.captionCircle {
  margin-top: 3px;
  font-size: 15px;
  font-weight: bold;
  color: #ccc;
}

.image-gallery-slides {
  border: 4px double #7D4427;
  box-shadow: 0px 4px 8px rgb(162, 197, 35, 0.2);
  border-radius: 8px;
}
